<template>
	<div>
		<router-link :class="{'card overflow-hidden rounded cursor-pointer text-decoration-none':true, 'border border-primary':proximamenteDF}" :to="{name:'opciones-inversion.show', params:{id:prenda.idprenda}}">
			<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':!(prenda.estado==1 || proximamenteDF)}">
				<div v-if="false">
					<button type="button" class="btn btn-link text-danger border-0 mr-2" title="Quitar de favoritos" v-on:click="prendaDeleteFavorite(prenda.idprenda)" v-if="prenda.favorito && prenda.estado != 5">
						<i class="material-icons">favorite</i>
					</button>
					<button type="button" class="btn btn-link mr-2" title="Añadir a favoritos" v-on:click="prendaAddFavorite(prenda.idprenda)" v-if="!prenda.favorito && prenda.estado != 5">
						<i class="material-icons">favorite_border</i>
					</button>
				</div>

				<span v-if="proximamenteDF">
					<img v-lazy="prenda.icono" :class="{'w-100 h-180 rounded-top':true, 'p-4':proximamenteDF}" :alt="prenda.nombre" style="object-fit: contain; height: 160px;" v-if="prenda.icono">
				</span>
				<img v-lazy="$filters.storageMin(prenda.ruta)" class="w-100 h-180 rounded-top" :alt="prenda.nombre" style="object-fit: cover; height: 160px;" v-else>
				<Pool v-if="prenda.inversionista == 2" class="position-absolute" style="right: 10px; top: 10px; cursor: help;"/>
				<span class="badge badge-light position-absolute" v-if="prenda.inversionista == 2" style="left: 10px; bottom: 5px;">
					Recaudado: {{$filters.currency(prenda.montoRecaudado)}}
				</span>
				<span class="badge badge-primary position-absolute" v-if="prenda.inversionista == 2" style="left: 10px; top: 10px;">
					inversionistas: {{prenda.inversores}}
				</span>
			</div>
			<div :class="{'progress rounded-0 position-relative':true, 'bg-secondary-light':prenda.premium!=1, 'bg-warning':prenda.premium==1}" style="height: 24px; font-size: 1rem;" v-if="prenda.inversionista == 2">
				<div :class="{'progress-bar progress-bar-striped progress-bar-animated font-weight-bold':true, 'bg-secondary':prenda.estado==1 && prenda.premium != 1, 'bg-gray':prenda.estado!=1, 'bg-warning':prenda.premium == 1}" role="progressbar" style="width: {{porcentajePrenda(prenda)}}%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
				</div>
				<div class="d-flex justify-content-between position-absolute align-items-center" style="left: 0px; right: 0px; top: 0px; bottom: 0px;">
					<div class="pl-2 font-weight-bold text-white text-left" v-if="proximamenteDF">
						Próximamente
					</div>
					<div class="pl-2 font-weight-bold text-white" v-else>
						<div v-if="prenda.estado==1 && prenda.inversionista != 2" class="text-left">
							Invierte
						</div>
						<div v-if="prenda.estado==1 && prenda.inversionista == 2" style="font-size: 95%;" class="text-left">
							Inversión colectiva
						</div>
						<div v-if="prenda.estado!=1"  class="text-left">
							Préstamo otorgado
						</div>
					</div>
					<div>
						<span class="badge badge-primary">{{porcentajePrenda(prenda)}}%</span>
					</div>
				</div>
			</div>
			<div :class="{'px-2 font-weight-bold text-white':true, 'bg-inversion':prenda.estado==1 && !proximamenteDF, 'bg-gray':prenda.estado!=1 && !proximamenteDF, 'bg-primary':proximamenteDF}" v-if="prenda.inversionista != 2">
				<div v-if="proximamenteDF" class="text-left">
					Próximamente
				</div>
				<div v-else>
					<div v-if="prenda.estado==1" class="text-left">
						Invierte
					</div>
					<div v-if="prenda.estado!=1" class="text-left">
						Préstamo otorgado
					</div>
				</div>
			</div>
			<div :class="{'text-white py-2 px-3 h-100 text-left':true, 'bg-secondary':proximamenteDF, 'bg-primary':!proximamenteDF}">
				<div class="text-truncate ">
					<span class="font-weight-bold" v-if="proximamenteDF">{{prenda.categoria}}</span>
					<span class="font-weight-bold" v-else>{{prenda.nombre}}</span>
				</div>
				> monto: <small>{{$filters.currency(prenda.precio)}}</small>
			</div>
		</router-link>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const Pool = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Pool" */"@/components/Pool.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		props: ['data', 'proximamente'],
		components: {
			Pool
		},
		methods: {
			prendaDeleteFavorite(id){
				this.$store.dispatch('prendaDeleteFavorite', {
					idusuario: this.user.idusuario,
					idprenda: id,
					fecha: Math.round(new Date().getTime()/1000.0)
				}).then(response => {
					//this.getPublicacion()
					window.toastr.info('Favorito eliminado.', 'Aviso')
					return response
				}).catch(error =>{
					return error
				})
			},
			prendaAddFavorite(id){
				this.$store.dispatch('prendaAddFavorite', {
					idusuario: this.user.idusuario,
					idprenda: id,
					fecha: Math.round(new Date().getTime()/1000.0)
				}).then(response => {
					//this.getPublicacion()
					window.toastr.info('Favorito agregado.', 'Aviso')
					return response
				}).catch(error =>{
					return error
				})
			},
			porcentajePrenda(prenda){
				return this.trunc(((prenda.montoRecaudado*100)/prenda.precio), 2)
			},

			trunc(x, posiciones = 0) {
				var s = x.toString()
				var l = s.length
				var decimalLength = s.indexOf('.') + 1

				if (l - decimalLength <= posiciones){
				  return x
				}
				// Parte decimal del número
				var isNeg  = x < 0
				var decimal =  x % 1
				var entera  = isNeg ? Math.ceil(x) : Math.floor(x)
				// Parte decimal como número entero
				// Ejemplo: parte decimal = 0.77
				// decimalFormated = 0.77 * (10^posiciones)
				// si posiciones es 2 ==> 0.77 * 100
				// si posiciones es 3 ==> 0.77 * 1000
				var decimalFormated = Math.floor(
				  Math.abs(decimal) * Math.pow(10, posiciones)
				)
				// Sustraemos del número original la parte decimal
				// y le sumamos la parte decimal que hemos formateado
				var finalNum = entera + 
				  ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1))
				return finalNum
			}
		},
		computed:{
			prenda(){
				return this.data || {}
			},

			proximamenteDF(){
				return this.proximamente || false
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
		}
	}
</script>